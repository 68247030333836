import React, { useEffect, useState } from "react";
import "./PunishmentList.css";

const PunishmentList = () => {
	const [searchTerm, setSearchTerm] = useState(" ");
	const [punishments, setPunishments] = useState([]);
	const [pageSize] = useState(100);
	const [totalPages, setTotalPages] = useState(0);
	const [page, setPage] = useState(1);
	const [filteredPunishments, setFilteredPunishments] = useState([]);

	useEffect(() => {
		// fetch("https://bans.jackpotmc.com/backend/api/punishments/")
		// fetch("http://localhost:8080/api/punishments/")
		// 	.then((response) => response.json())
		// 	.then((response) => {
		// 		setPunishments(response);
		// 	});
		fetchPunishments();
		fetchTotalPages();
	}, [page, searchTerm]);

	const fetchPunishments = async () => {
		try {
			const response = await fetch(
				`https://bans.jackpotmc.com/backend/api/punishments/search/${
					searchTerm === "" ? " " : searchTerm
				}/${page}/${pageSize}`
			);
			const data = await response.json();
			setPunishments(data);

			setFilteredPunishments(
				data
					.filter(
						(punishment) =>
							punishment.playerName
								.toLowerCase()
								.includes(searchTerm.toLowerCase()) ||
							searchTerm === "" ||
							searchTerm === " " &&
							!punishment.duration.includes("-")
					)
					.sort((a, b) => b.time - a.time)
			);
		} catch (error) {
			console.log("error fetching punishments: ", error);
		}
	};

	const fetchTotalPages = async () => {
		try {
			const response = await fetch(
				"https://bans.jackpotmc.com/backend/api/punishments/count"
			);
			const totalPunishments = await response.json();
			setTotalPages(Math.ceil(totalPunishments / pageSize));
		} catch (error) {
			console.error("Error fatching total punishments: ", error);
		}
	};

	const handleNextPage = () => {
		if (page < totalPages - 1) {
			setPage(page + 1);
		}
	};

	const handlePreviousPage = () => {
		if (page > 0) {
			setPage(page - 1);
		}
	};

	return (
		<>
			<div className="punishment-list-container">
				<div className="search-bar-container">
					<input
						type="text"
						placeholder="Search by username..."
						className="search-bar"
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}
					/>
				</div>
				{filteredPunishments.length > 0 ? (
					<>
						<div className="punishment-list">
							{filteredPunishments.map((punishment, index) => (
								<div
									key={index}
									className={`punishment-card ${punishment.punishmentType}`}
								>
									<div className="punishment-item">
										<span className="label">Username:</span>{" "}
										{punishment.playerName}
									</div>
									<div className="punishment-item">
										<span className="label">Reason:</span> {punishment.reason}
									</div>
									<div className="punishment-item">
										<span className="label">Length:</span> {punishment.duration}
									</div>
									<div className="punishment-item">
										<span className="label">Punisher:</span>{" "}
										{punishment.adminName}
									</div>
								</div>
							))}
						</div>
						<div className="pagination-controls">
							<button
								disabled={page === 1}
								onClick={() => handlePreviousPage()}
							>
								Previous
							</button>
							<span>Page {page}</span>
							<button
								disabled={page === totalPages}
								onClick={() => handleNextPage()}
							>
								Next
							</button>
						</div>
					</>
				) : (
					<div>No punishments found...</div>
				)}
			</div>
		</>
	);
};

export default PunishmentList;
